<template>
  <div @click="$emit('click', data)">
    <card class="cursor-pointer">
      <package-item-header :data="data"></package-item-header>
      <div class="flex flex-col items-end">
        <strong class="mb-2">{{data.Amount}}</strong>
        <b-tag :type="data.Status.Type">{{data.Status.Text}}</b-tag>
      </div>
    </card>
  </div>
</template>

<script>
import PackageItemHeader from './PackageItemHeader'
import Card from '../common/Card'
export default {
  components: {
    PackageItemHeader,
    Card
  },
  props: {
    data: Object
  }
}
</script>

<style>

</style>
